<template>
  <div>
    <!-- 客服管理 -->
    <div class="content-wrap">
      <area-tree @setQueryParams="handleQuery" >
        <el-form :model="queryParams"  size="small" :inline="true">
          <el-form-item >
            <el-select v-model="queryParams.serviceType"  placeholder="请选择客服类型" clearable filterable @change="refresh">
              <el-option label="运维客服" :value="1"></el-option>
              <el-option label="移动客服" :value="2"></el-option>
              <el-option label="联通客服" :value="3"></el-option>
              <el-option label="电信客服" :value="4"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        
        <div class="btn-wrap cfx">
          <el-button type="success" size="small" icon="el-icon-plus" @click="handleForm(false)" v-if="OA.includes('service:customer:add')">添加</el-button>
        </div>

        <!--列表-->
        <paged-table :data="bServiceList" :total="bServiceTotal"
          :loading="tLoading" :isShowSelection="false" defaultSortProp="id"
          v-model="queryParams" @refresh="refresh"> 
          <el-table-column sortable="custom" prop="extend.schoolName" show-overflow-tooltip label="学校" width="250"></el-table-column>
          <el-table-column sortable="custom" prop="serviceType" show-overflow-tooltip label="客服类型">
            <template slot-scope="scope">
              <span v-if="scope.row.serviceType==1">运维客服</span>
              <span v-else-if="scope.row.serviceType==2">移动客服</span>
              <span v-else-if="scope.row.serviceType==3">联通客服</span>
              <span v-else>电信客服</span>
            </template>
          </el-table-column>
          <el-table-column sortable="custom" prop="contactType" show-overflow-tooltip label="联系方式类型">
            <template slot-scope="scope">
              <span v-if="scope.row.contactType==1">手机</span>
              <span v-else-if="scope.row.contactType==2">座机</span>
              <span v-else-if="scope.row.contactType==3">微信</span>
              <span v-else>QQ</span>
            </template>
          </el-table-column>
          <el-table-column sortable="custom" prop="contact" show-overflow-tooltip label="联系方式"></el-table-column>
          <el-table-column sortable="custom" prop="remark" show-overflow-tooltip label="备注" align="center"></el-table-column>
          <el-table-column sortable="custom" prop="addTime" show-overflow-tooltip label="创建时间"></el-table-column>
          <el-table-column sortable="custom" prop="extend.addUserName" show-overflow-tooltip label="操作者"></el-table-column>
          <el-table-column align="right" label="操作" width="120" fixed="right">
            <template slot-scope="scope">
              <el-button size="mini" @click="handleDetails(scope.row)" icon="el-icon-search" title="详情"></el-button>
              <el-button size="mini" type="primary" @click="handleForm(true, scope.row,afterFun)" icon="el-icon-edit" title="编辑" v-if="OA.includes('service:customer:editor')" ></el-button>
              <el-button size="mini" type="danger" @click="handleDelete({id: scope.row.id})" icon="el-icon-delete" title="删除" v-if="OA.includes('service:customer:del')"></el-button>
            </template>
          </el-table-column>
        </paged-table>
      </area-tree>
    </div>

    <!-- 编辑界面 -->
    <el-dialog :title="isEditor ? '编辑客服' : '添加客服'" :visible.sync="aVisible" width="500px" :close-on-click-modal="false">
      <el-form label-width="120px" ref="formFileds" :model="form" :rules="formRules" size="small">
        <el-form-item  label="选择学校：" prop="schoolId">
          <el-select  v-model="form.schoolId" @change="fetchSchoolDicts" clearable filterable placeholder="请选择学校">
              <el-option 
              v-for="(item,index) in schoolAll"
              :label="item.schoolName" 
              :value="item.id"
              :key="index"
              ></el-option>
            </el-select>
        </el-form-item>
        <el-form-item  label="选择校区：" prop="distId">
          <el-select   v-model="form.distId" clearable filterable placeholder="请选择校区">
              <el-option 
              v-for="(item,index) in schoolDicts"
              :label="item.distName" 
              :value="item.id"
              :key="index"
              ></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="客服类型：" prop="serviceType">
          <el-select v-model="form.serviceType" clearable filterable placeholder="请选择客服类型">
            <el-option label="运维客服" :value="1"></el-option>
            <el-option label="移动客服" :value="2"></el-option>
            <el-option label="联通客服" :value="3"></el-option>
            <el-option label="电信客服" :value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="联系类型：" prop="contactType">
          <el-select v-model="form.contactType" clearable filterable placeholder="请选择联系类型">
            <el-option label="手机" :value="1"></el-option>
            <el-option label="座机" :value="2"></el-option>
            <el-option label="微信" :value="3"></el-option>
            <el-option label="QQ" :value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="联系方式：" prop="contact">
          <el-input v-model="form.contact" placeholder="请输入联系方式" clearable></el-input>
        </el-form-item>
        <el-form-item label="备注：" prop="remark">
          <el-input type="textarea" placeholder="请输入备注" v-model="form.remark" clearable :row="3"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="aVisible = false">取消</el-button>
        <el-button size="small" type="primary" :loading="bLoading" @click="isEditor ? submitForm(editorApi) : submitForm(insertApi)">保存</el-button>
      </div>
    </el-dialog>

    <!-- 详情界面 -->
    <el-dialog title="客服详情" :visible.sync="bVisible" width="500px" :close-on-click-modal="false">
      <el-form label-width="100px" ref="detailsFileds" :model="details" size="small">
        <el-form-item label="学校名称：" prop="extend.schoolName">
          <span v-text="details.extend.schoolName"></span>
        </el-form-item>
        <el-form-item label="客服类型：" prop="serviceType">
          <span v-if="details.serviceType==1">运维客服</span>
          <span v-else-if="details.serviceType==2">移动客服</span>
          <span v-else-if="details.serviceType==3">联通客服</span>
          <span v-else>电信客服</span>
        </el-form-item>
        <el-form-item label="联系类型：" prop="contactType">
          <span v-if="details.contactType==1">手机</span>
            <span v-else-if="details.contactType==2">座机</span>
            <span v-else-if="details.contactType==3">微信</span>
            <span v-else>QQ</span>
        </el-form-item>
        <el-form-item label="联系方式：" prop="contact">
          <span v-text="details.contact"></span>
        </el-form-item>
        <el-form-item label="备注：" prop="remark">
          <span v-text="details.remark"></span>
        </el-form-item>
        <el-form-item label="创建时间：" prop="addTime">
          <span v-text="details.addTime"></span>
        </el-form-item>
        <el-form-item label="操作者：" prop="extend.updUserName">
          <span v-text="details.extend.updUserName"></span>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapActions, mapState } from "vuex";
import PagedTable from "@/components/common/PagedTable";
import BaseUpload from "@/components/common/BaseUpload";
import AreaTree from "@/components/common/AreaTree";
import pageMixin from "@/mixins/pageMixin";
import provinces from "@/mixins/provinces";
import { log, mergeObject } from '@/common/utils'
export default {
  name:'serviceCustomer',
  components: {
    AreaTree,
    PagedTable,
    BaseUpload
  },
  mixins: [pageMixin, provinces],
  data() {
    return {
      queryParams: {
        name: null,
      },
      userRoleIds:[],
      roleOptions:[],
      // 所有学校
      schoolAll:[],
      // 所有校区
      schoolDicts:'',
      form:{
        id:'',
        schoolId:'',
        distId:'',
        serviceType:'',
        contactType:'',
        contact:'',
        remark:'',
      },
      details:{
        extend:{},
        serviceType:'',
        contactType:'',
        contact:'',
        remark:'',
        addTime:'',
      },
      formRules:{
        schoolId: [{ required: true, message: '请选择学校', trigger: 'blur' }],
        distId: [{ required: true, message: '请选择校区', trigger: 'blur' }],
        serviceType: [{ required: true, message: '请选择客服类型', trigger: 'blur' }],
        contactType: [{ required: true, message: '请选择联系类型', trigger: 'blur' }],
        contact: [{ required: true, message: '请输入联系方式', trigger: 'blur' }],
        remark: [{ required: true, message: '请输入备注', trigger: 'blur' }],
      },

      pageApi:'bCustomerList',
      insertApi:'bCustomerAdd',
      editorApi:'bCustomerEdit',
      deleteApi:'bCustomerDelete',
    };
  },
  computed: {
    ...mapState(["OA"]),
    ...mapState("serviceCustomer", ["bServiceList", "bServiceTotal"]),
  },
  methods: {
    ...mapActions("broadBand",["getSchoolDicts", "getSchoolAll",]),
    ...mapActions("serviceCustomer",["bCustomerList", "bCustomerAdd", "bCustomerEdit", "bCustomerDelete"]),
    // 获取学校
    async fetchSchoolAll() {
        const res = await this.getSchoolAll({})
        this.schoolAll = res
        // console.log(res,"xuexiao");
    },
    afterFun(row) {
      console.log(row)
      this.fetchSchoolDicts(row.schoolId)
    },
    // 获取校区
    async fetchSchoolDicts(val) {
      console.log(val)
        const res = await this.getSchoolDicts({schoolId:val})
        this.schoolDicts = res
    },
    async submitForm(api) {
      let params = Object.assign({}, this.form)
      this.$refs.formFileds.validate((valid) => {
        if (valid) {
          this.$confirm('确认提交保存吗？', '提示', {}).then(async () => {
            this.bLoading = true
            try {
              if(this.isEditor){
                delete params.userName
                delete params.mobile
                delete params.password
              }
              await this[api](params)
              this.$message.success('数据保存成功！')
              this.aVisible = false
              this.refresh()
            }catch(e){
              log(e)
            }finally {
              this.bLoading = false
            }
          })
        }
      })
    },
    // 地区
    handleQuery(data){
      if ((data.id + "").length === 9) {
        this.queryParams.province = data.id;
        this.queryParams.city = null;
        this.queryParams.area = null;
      }
      if ((data.id + "").length === 12) {
        this.queryParams.city = data.id;
        this.queryParams.area = null;
      }
      if ((data.id + "").length === 15) {
        this.queryParams.area = data.id;
      }
      if ((data.id + "").length <9) {
        this.queryParams.schoolId = data.id;
        this.initParam.schoolId = data.id;
      }
      this.refresh()
    },
    
  },
  async mounted() {
    if(this.getPageParams(this.$options.name))
      mergeObject(this.queryParams, this.getPageParams(this.$options.name))
    this.refresh()
    this.fetchSchoolAll()
  }
};
</script>